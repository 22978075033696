@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Festive&family=Henny+Penny&family=Montez&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Festive&family=Henny+Penny&family=Montez&family=Passion+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Festive&family=Henny+Penny&family=Montez&family=Passion+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.mainNavLogoh1 {
	font-size: calc(20px + 5vh);
	height: 120px;
	margin-top: -25px;
	line-height: calc(20px + 20vh);
  /*   text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
	  0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092;
	color: #fccaff; */
	text-shadow: 0 0 5px #2dd4bf, 0 0 15px #2dd4bf, 0 0 20px #2dd4bf, 0 0 40px #2dd4bf, 0 0 60px #eef2ff, 0 0 10px #0d9488, 0 0 98px #eef2ff;
	  color: #fff6a9;
	font-family: "Festive", cursive;
	text-align: center;
	animation: blink 12s infinite;
	-webkit-animation: blink 12s infinite;
  }
  
  
  @-webkit-keyframes blink {
	20%,
	24%,
	55% {
	  color: #111;
	  text-shadow: none;
	}
  
	0%,
	19%,
	21%,
	23%,
	25%,
	54%,
	56%,
	100% {
  /*     color: #fccaff;
	  text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
		0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
	text-shadow: 0 0 5px #2dd4bf, 0 0 15px #2dd4bf, 0 0 20px #2dd4bf, 0 0 40px #2dd4bf, 0 0 60px #eef2ff, 0 0 10px #0d9488, 0 0 98px #eef2ff;
	  color: #fff6a9;
	}
  }
  
  @keyframes blink {
	20%,
	24%,
	55% {
	  color: #111;
	  text-shadow: none;
	}
  
	0%,
	19%,
	21%,
	23%,
	25%,
	54%,
	56%,
	100% {
  /*     color: #fccaff;
	  text-shadow: 0 0 5px #f562ff, 0 0 15px #f562ff, 0 0 25px #f562ff,
		0 0 20px #f562ff, 0 0 30px #890092, 0 0 80px #890092, 0 0 80px #890092; */
	text-shadow: 0 0 5px #2dd4bf, 0 0 15px #2dd4bf, 0 0 20px #2dd4bf, 0 0 40px #2dd4bf, 0 0 60px #eef2ff, 0 0 10px #0d9488, 0 0 98px #eef2ff;
	  color: #fff6a9;
	}
  }

:root{
	--fc-border-color: black;
	--fc-today-bg-color:#a6f0e6;

	--fc-small-font-size: .85em;
	--fc-page-bg-color: #fff;
	--fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
	--fc-neutral-text-color: #808080;
	
  
	--fc-button-text-color: #fff;
	--fc-button-bg-color: black;
	--fc-button-border-color: black;
	--fc-button-hover-bg-color: black;
	--fc-button-hover-border-color: black;
	--fc-button-active-bg-color: black;
	--fc-button-active-border-color: black;
  
	--fc-event-bg-color: white;
	--fc-event-border-color: #2dd4bf;
	--fc-event-text-color: black;
	--fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  
	--fc-more-link-bg-color: #d0d0d0;
	--fc-more-link-text-color: inherit;
  
	--fc-event-resizer-thickness: 8px;
	--fc-event-resizer-dot-total-width: 8px;
	--fc-event-resizer-dot-border-width: 1px;
  
	--fc-non-business-color: rgba(215, 215, 215, 0.3);
	--fc-bg-event-color: rgb(143, 223, 130);
	--fc-bg-event-opacity: 0.3;
	--fc-highlight-color: rgba(188, 232, 241, 0.3);
	--fc-now-indicator-color: red;
}

.fc-theme-standard {
	& td,
	& th {
		border: 0.7px solid black;
	}
  }
.fc {
	& table {
		border-collapse: collapse;
		border-spacing: 0;
		font-size: 1em; 
	}
	& .fc-button {
	  border-radius: 20;
	  background-color:white;
	  border: 1px #d0d0d0 solid;
	  color: black;
	  overflow: visible;
	  text-transform: capitalize;
	  margin: 0;
	  font-family: inherit;
	  font-size: inherit;
	  line-height: inherit;
	}
	& .fc-button:hover {
		background-color: black;
		border: 1px solid #eef2ff;
	}
	& .fc-button:focus {
		outline: 0;
		box-shadow: none;
	}
	& .fc-button:focus {
		outline: 1px dotted;
		outline: 1px auto -webkit-focus-ring-color;
	}
	& .fc-toolbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		/* background-image: url('https://res.cloudinary.com/defonzszt/image/upload/v1695483755/exuo70ok6ozknjnoj61u.png'); */
		background-repeat: no-repeat;
		background-size: cover;
	}
	& .fc-toolbar.fc-header-toolbar {
		margin-bottom: 1rem;
		/* visibility: hidden; */
	}
	& .fc-toolbar-title {
		font-size: 1.2rem;
		display: block;
		margin: 0;
		font-weight: 500;
		color: #0b1437;
		font-family: "inter var", "sans-serif";
	  }
}


/* Basic scrollbar styles */
::-webkit-scrollbar {
	width: 10px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-track {
	background: inherit; /* Track color (the area behind the thumb) */ 
  }
  
  ::-webkit-scrollbar-thumb {
	background: #2dd4bf; /* Thumb color (the draggable part of the scrollbar) */
	border-radius: 5px; /* Rounded corners for the thumb */
  }
  
  /* On hover, style the thumb */
  ::-webkit-scrollbar-thumb:hover {
	background: #2fecd3;
  }
  
  /* Customize the corner (where vertical and horizontal scrollbars meet) */
  ::-webkit-scrollbar-corner {
	background: #f1f1f1;
  }
  
.buttons-page-length{
	display: none;
}  